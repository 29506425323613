import * as React from "react";
import { createGenericContext } from "utils/createGenericContext";
import * as ls from "local-storage";

type UseGlobalState = {
  loggedInSlug?: string;
  setLoggedInSlug: (slug: string) => void;
};

const [useGlobalStateContext, GlobalStateContextProvider] =
  createGenericContext<UseGlobalState>();

const GlobalStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedInSlug, setLoggedInSlug] = React.useState<string>(
    ls.get("slug")
  );

  return (
    <GlobalStateContextProvider
      value={{
        loggedInSlug,
        setLoggedInSlug: (slug) => {
          ls.set("slug", slug);
          setLoggedInSlug(slug);
        },
      }}
    >
      {children}
    </GlobalStateContextProvider>
  );
};

export { useGlobalStateContext, GlobalStateProvider };
