import React from "react";
import cx from "classnames";
import styles from "./Device.module.scss";

function Device({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.innerInner}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Device;
