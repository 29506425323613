import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useGlobalStateContext } from "hooks/useGlobalState";
import { useRef, useState } from "react";
import { useWindowSize } from "rooks";
import * as routes from "routes";
import {
  defaultTokenData,
  normalizeTokenData,
  SignInErrors,
  signInWithSlug,
  slugToId,
} from "utils/chain";
import styles from "./Hero.module.scss";
import Device from "components/Device";
import Profile from "components/Profile";
import { Logo } from "components/Logo";
import { Link } from "../Root";
import { useEnv } from "utils/env";
import { Loader } from "components/Page";
import ExampleProfile from "utils/example.json";
import isValidBrowser from "utils/isValidBrowser";
import classNames from "classnames";
import { letterOnlyPattern, MAX } from "utils/slug";

export default function Hero() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setLoggedInSlug, loggedInSlug } = useGlobalStateContext();
  const [slug, setSlug] = useState(loggedInSlug);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessageString, setErrorMessageString] = useState("");
  const navigate = useNavigate();
  const { innerWidth } = useWindowSize();
  const isDesktop = innerWidth && innerWidth > 600;
  const canLogIn = isDesktop && isValidBrowser();

  const showError = (errorCode: SignInErrors, errorMessage?: string) => {
    if (errorMessage) {
      setErrorMessageString(errorMessage);
      return;
    }

    setErrorMessageString(
      {
        [SignInErrors.NO_MATCH]: "This username is owned by another wallet.",
        [SignInErrors.NO_METAMASK]: "Install MetaMask to Connect.",
        [SignInErrors.INVALID_SLUG]: "Invalid username.",
        [SignInErrors.UNKNOWN]: "Error connecting. Make sure you're connected to the Polygon Mainnet in your MetaMask wallet. Reload page and try again. See the \"How do I create a profile?\" section below for more information.",
      }[errorCode]
    );
  };

  const signIn = async () => {
    if (!slug) {
      return;
    }

    setIsSigningIn(true);

    const { error, message: errorMessage } = await signInWithSlug(slug);

    if (!error) {
      setErrorMessageString("");
      setLoggedInSlug(slug);
      navigate(routes.admin);
    } else {
      showError(error, errorMessage);
    }

    setIsSigningIn(false);
  };

  const isValidSlug = slug && !slug.match(letterOnlyPattern);

  return (
    <>
      {isSigningIn && (
        <Loader isOverlay>
          <CircularProgress color="inherit" />
        </Loader>
      )}
      <div
        className={classNames(styles.container, {
          [styles.isMobile]: !isDesktop,
        })}
      >
        <div className={styles.inner}>
          <div className={styles.left}>
            <div className={styles.logo}>
              <Logo showBeta />
            </div>
            <h1 className={styles.headline}>
              The first decentralized
              <br />
              social bio tool.
            </h1>
            <h2 className={styles.subtitle}>
              Mint your profile as an NFT on the Polygon network. You own the
              token that represents your profile and all metadata is kept in
              decentralized storage. View the{" "}
              <Link href={useEnv.ledgerContract}>public ledger</Link>, or
              explore profile NFTs on{" "}
              <Link href={useEnv.openSeaCollection}>OpenSea</Link>.
            </h2>
            <div className={styles.inputWrapper}>
              <span
                onClick={() => {
                  inputRef.current?.focus();
                }}
                className={styles.prefix}
              >
                onchain.bio/
              </span>
              <input
                ref={inputRef}
                autoFocus
                maxLength={MAX}
                className={styles.input}
                type="text"
                value={slug}
                onChange={(e) => {
                  setErrorMessageString("");
                  setSlug(e.target.value.toLowerCase());
                }}
                onKeyPress={(e) => {
                  if (e.code === "Enter") {
                    signIn();
                  }
                }}
              />
            </div>
            {slug && (
              <div className={styles.tokenId}>
                <span>tokenId:</span>
                {isValidSlug ? <>{slugToId(slug)}</> : "Invalid slug"}
              </div>
            )}
            {errorMessageString && (
              <div className={styles.error}>{errorMessageString}</div>
            )}
            {!canLogIn && (
              <div className={styles.error}>
                You must use the Chrome desktop browser to connect to
                onchain.bio to mint or update a profile.
              </div>
            )}
            <Button
              size="large"
              variant="contained"
              disabled={!slug || !canLogIn}
              onClick={signIn}
              sx={{ mt: 3 }}
            >
              Connect
            </Button>
          </div>
          <div className={styles.right}>
            <Device>
              <Profile profile={normalizeTokenData(ExampleProfile)} />
            </Device>
          </div>
        </div>
      </div>
    </>
  );
}
