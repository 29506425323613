import { ethers } from "ethers";
import { create } from "ipfs-http-client";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/browser";

import { useEnv } from "./env";
import abi from "./abi.json";
import { IPFSHTTPClient } from "ipfs-http-client/types/src/types";
import axios from "axios";
import { letterMapping } from "./constants";
import { urlToFile } from "./file";
import { validateSlug } from "./slug";

export enum SocialLinkTypes {
  instagram = "instagram",
  twitter = "twitter",
  tiktok = "tiktok",
  twitch = "twitch",
  reddit = "reddit",
  github = "github",
  opensea = "opensea",
}

export type tokenSocialLink = {
  id: string;
  type: SocialLinkTypes;
  username: string;
};

export type tokenLink = {
  id: string;
  title: string;
  url: string;
};

export type tokenData = {
  slug: string;
  name: string;
  description: string;
  image?: File | string;
  color1: string;
  color2: string;
  socialLinks: tokenSocialLink[];
  links: tokenLink[];
};

type tokenDataFinal = Omit<tokenData, "image"> & {
  image?: string;
};

type TokenAttribute = {
  trait_type: string;
  value: string;
};

enum AttributePrefixes {
  social = "Social",
  link = "Link",
  color1 = "Color 1",
  color2 = "Color 2",
  slug = "Username",
}

type RawTokenData = {
  name: string;
  description: string;
  image?: string;
  external_url: string;
  attributes: TokenAttribute[];
};

const transformTokenData = ({
  name,
  description,
  slug,
  image,
  color1,
  color2,
  socialLinks,
  links,
}: tokenDataFinal): RawTokenData => {
  const attributes = [
    {
      trait_type: AttributePrefixes.slug,
      value: slug,
    },
    {
      trait_type: AttributePrefixes.color1,
      value: color1,
    },
    {
      trait_type: AttributePrefixes.color2,
      value: color2,
    },
    ...socialLinks.map((link) => ({
      trait_type: `${AttributePrefixes.social}:${link.type}`,
      value: link.username,
    })),
    ...links.map((link) => ({
      trait_type: `${AttributePrefixes.link}:${link.title}`,
      value: link.url,
    })),
  ];

  return {
    name,
    description,
    image,
    attributes,
    external_url: `https://onchain.bio/${slug}`,
  };
};

export const normalizeTokenData = ({
  name,
  description,
  image,
  attributes,
  external_url,
}: RawTokenData): tokenData => {
  const color1 = attributes.find(
    (attr) => attr.trait_type === AttributePrefixes.color1
  )!.value;
  const color2 = attributes.find(
    (attr) => attr.trait_type === AttributePrefixes.color2
  )!.value;
  const slug =
    attributes.find((attr) => attr.trait_type === AttributePrefixes.slug)
      ?.value || "";
  const socialLinks = attributes
    .filter((attr) => attr.trait_type.startsWith(AttributePrefixes.social))
    .map((attr) => ({
      id: uuidv4(),
      type: attr.trait_type.replace(
        `${AttributePrefixes.social}:`,
        ""
      ) as SocialLinkTypes,
      username: attr.value,
    }));
  const links = attributes
    .filter((attr) => attr.trait_type.startsWith(AttributePrefixes.link))
    .map((attr) => ({
      id: uuidv4(),
      title: attr.trait_type.replace(`${AttributePrefixes.link}:`, ""),
      url: attr.value,
    }));

  return {
    name,
    description,
    slug,
    image: image ? ipfsUrlToInfura(image) : image,
    color1,
    color2,
    socialLinks,
    links,
  };
};

export const defaultTokenData: tokenData = {
  slug: "",
  name: "",
  description: "",
  image: undefined,
  color1: "#000000",
  color2: "#000000",
  socialLinks: [
    { id: uuidv4(), type: SocialLinkTypes.instagram, username: "tcom" },
  ],
  links: [
    { id: uuidv4(), title: "My Link", url: "google.com" },
    { id: uuidv4(), title: "My Link 2", url: "google.com" },
  ],
};

const metamask = (() => {
  // @ts-ignore
  const mm = window.ethereum;
  return mm as any;
})();

const isMetamaskConnected = () => {
  return !!metamask;
};

export const getCurrentAddress = async () => {
  if (isMetamaskConnected()) {
    const accounts = await metamask.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    return account;
  }
};

let web3Provider: ethers.providers.Web3Provider;
const getWeb3Provider = () => {
  web3Provider = web3Provider || new ethers.providers.Web3Provider(metamask);
  return web3Provider;
};

let rpcProvider: ethers.providers.JsonRpcProvider;
const getRpcProvider = () => {
  rpcProvider =
    rpcProvider ||
    new ethers.providers.JsonRpcProvider(useEnv.infuraEthAddress);
  return rpcProvider;
};

let ipfsClient: IPFSHTTPClient;
export const getIpfsClient = () => {
  if (!ipfsClient) {
    const { infuraIpfsProjectId, infuraIpfsProjectSecret } = useEnv;
    const auth =
      "Basic " +
      Buffer.from(infuraIpfsProjectId + ":" + infuraIpfsProjectSecret).toString(
        "base64"
      );

    ipfsClient = create({
      host: "ipfs.infura.io",
      port: 5001,
      protocol: "https",
      headers: {
        authorization: auth,
      },
    });
  }
  return ipfsClient;
};

const doAddressesMatch = (addr1: string, addr2: string) => {
  return addr1.toLowerCase() === addr2.toLowerCase();
};

export const slugToId = (slug: string) => {
  const stringVal = slug
    .split("")
    .map((char) => {
      // @ts-ignore
      return letterMapping[char];
    })
    .join("");

  return "1" + stringVal;
};

export const ipfsUrl = (hash: string) => `ipfs://${hash}`;

export const ipfsUrlToInfura = (url: string) => {
  const [other, hash] = url.split("ipfs://");
  return hash ? `https://ipfs.io/ipfs/${hash}` : other;
};

export const uploadIpfsData = async (data: RawTokenData) => {
  const client = getIpfsClient();
  const added = await client.add(JSON.stringify(data));
  await client.pin.add(added.path);
  return added.path;
};

export const uploadIpfsFile = async (file: File) => {
  const client = getIpfsClient();
  const added = await client.add(file);
  await client.pin.add(added.path);
  return added.path;
};

console.log(slugToId("charlie"));

export const updateToken = async (slug: string, data: tokenData) => {
  const account = await getCurrentAddress();
  const provider = getWeb3Provider();
  const signer = provider.getSigner();
  const contract = new ethers.Contract(useEnv.contractAddress, abi, provider);
  const contractWithSigner = contract.connect(signer);

  const tokenId = slugToId(slug);
  const doesExist = await contract.doesTokenExist(tokenId);

  if (doesExist) {
    const owner = await contract.ownerOf(slugToId(slug));

    if (!doAddressesMatch(account, owner)) {
      return { error: "NO_MATCH" };
    }
  }

  let image = undefined;

  if (data.image) {
    const imageHash = await uploadIpfsFile(data.image as File);
    image = ipfsUrl(imageHash);
  }

  const ipfsPath = await uploadIpfsData(
    transformTokenData({
      ...data,
      slug,
      image,
    })
  );
  const tokenUri = ipfsUrl(ipfsPath);

  if (doesExist) {
    await contractWithSigner.updateTokenUri(tokenId, tokenUri);
  } else {
    await contractWithSigner.safeMint(account, tokenId, tokenUri);
  }

  return { error: null };
};

export enum SignInErrors {
  NO_MATCH = "NO_MATCH",
  NO_METAMASK = "NO_METAMASK",
  UNKNOWN = "UNKNOWN",
  INVALID_SLUG = "INVALID_SLUG",
}

export const signInWithSlug = async (
  slug: string
): Promise<{ error?: SignInErrors; message?: string }> => {
  const slugErrorMessage = validateSlug(slug);

  if (!!slugErrorMessage) {
    return { error: SignInErrors.INVALID_SLUG, message: slugErrorMessage };
  }

  try {
    const account = await getCurrentAddress();

    if (!account) {
      return { error: SignInErrors.NO_METAMASK };
    }

    const provider = getWeb3Provider();
    const contract = new ethers.Contract(useEnv.contractAddress, abi, provider);

    const tokenId = slugToId(slug);

    const doesExist = await contract.doesTokenExist(tokenId);

    if (doesExist) {
      const owner = await contract.ownerOf(slugToId(slug));

      if (!doAddressesMatch(account, owner)) {
        return { error: SignInErrors.NO_MATCH };
      }
    }
  } catch (err: any) {
    if (err.message) {
      Sentry.captureException(new Error(err.message));
    } else {
      Sentry.captureException(err);
    }
    return { error: SignInErrors.UNKNOWN };
  }

  return {};
};

const getDataFromUri = async (tokenUri: string) => {
  const resp = await axios.get(ipfsUrlToInfura(tokenUri));

  const { data } = resp;

  return {
    ...defaultTokenData,
    ...normalizeTokenData(data as RawTokenData),
  };
};

export const getDataForSlug = async (slug: string) => {
  const tokenId = slugToId(slug);
  console.log(tokenId);
  const provider = getRpcProvider();
  const contract = new ethers.Contract(useEnv.contractAddress, abi, provider);
  const tokenUri = await contract.tokenURI(tokenId);
  return getDataFromUri(tokenUri);
};

export const getAdminDataForSlug = async (slug: string) => {
  const tokenId = slugToId(slug);
  const provider = getWeb3Provider();
  const contract = new ethers.Contract(useEnv.contractAddress, abi, provider);

  const doesExist = await contract.doesTokenExist(tokenId);
  const currentAddress = await getCurrentAddress();

  if (!doesExist) {
    return {
      doesExist: false,
      profile: { ...defaultTokenData, name: slug },
      currentAddress,
    };
  }

  const tokenUri = await contract.tokenURI(tokenId);
  const data = await getDataFromUri(tokenUri);

  let imageFile;

  if (data.image) {
    imageFile = await urlToFile(data.image as string);
  }

  return {
    doesExist: true,
    profile: { ...defaultTokenData, ...data, image: imageFile },
    currentAddress,
  };
};
