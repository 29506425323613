import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { FieldProps } from "formik";
import { SocialLinkTypes, tokenSocialLink } from "utils/chain";
import ReorderableContainer from "./ReorderableContainer";
import AddIcon from "@mui/icons-material/Add";
import { SocialConfig } from "utils/social";

export const validateSocialLinks = (links: tokenSocialLink[]) => {
  return !links.some((link) => link.username.trim() === "");
};

const SocialLinksField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue },
  ...props
}: FieldProps) => {
  const updateFieldByIndex = (
    changeIndex: number,
    newItem: Partial<tokenSocialLink>
  ) => {
    setFieldValue(
      name,
      (value as tokenSocialLink[]).map((item, index) => {
        if (index === changeIndex) {
          return { ...item, ...newItem };
        }
        return item;
      })
    );
  };
  return (
    <div>
      <ReorderableContainer
        value={value}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
        }}
        renderItem={(item: tokenSocialLink, index) => {
          return (
            <>
              <Select
                fullWidth
                sx={{ mr: 1 }}
                value={item.type}
                onChange={(e) => {
                  updateFieldByIndex(index, {
                    type: e.target.value as SocialLinkTypes,
                  });
                }}
              >
                {Object.values(SocialLinkTypes).map((type) => (
                  <MenuItem value={type}>{SocialConfig[type].label}</MenuItem>
                ))}
              </Select>
              <TextField
                placeholder="Username"
                fullWidth
                value={item.username}
                onChange={(e) =>
                  updateFieldByIndex(index, { username: e.target.value })
                }
              />
            </>
          );
        }}
        getId={(item: tokenSocialLink) => item.id}
      />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          const typeToAdd = Object.values(SocialLinkTypes).find((v) =>
            value.every((v2: tokenSocialLink) => v2.type !== v)
          );
          return setFieldValue(name, [
            ...value,
            {
              id: uuidv4(),
              type: typeToAdd || Object.values(SocialLinkTypes)[0],
              username: "",
            } as tokenSocialLink,
          ]);
        }}
      >
        Add
      </Button>
      {touched[name] && errors[name] && (
        <div style={{ color: "#d32f2f", marginTop: 10 }}>{errors[name]}</div>
      )}
    </div>
  );
};

export default SocialLinksField;
