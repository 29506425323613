import normalizeUrl from "normalize-url";
import usePromise from "react-use-promise";
import { tokenData } from "utils/chain";
import { fileToUrl, getImageUrl } from "utils/file";
import { SocialConfig } from "utils/social";
import { Logo } from "./Logo";
import styles from "./Profile.module.scss";
import cx from "classnames";

type ProfileProps = {
  profile: tokenData;
  isReal?: boolean;
};

function Profile({ profile, isReal }: ProfileProps) {
  const { name, description, image, socialLinks, links, color1, color2, slug } =
    profile;
  const [imageUrl] = usePromise(() => getImageUrl(image), [image]);

  return (
    <>
      <div className={cx(styles.container, { [styles.isReal]: isReal })}>
        <div
          className={styles.gradient}
          style={{ background: `linear-gradient(45deg, ${color1}, ${color2})` }}
        ></div>
        <div className={styles.profilePictureWrapper}>
          <div
            className={styles.profilePicture}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
        </div>
        <div className={styles.contents}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{description}</div>
          {socialLinks.length > 0 && (
            <div className={styles.socialLinks}>
              {socialLinks.map((link) => {
                const { Icon, getUrl } = SocialConfig[link.type];
                return (
                  <a
                    className={styles.socialLink}
                    key={link.id}
                    href={getUrl(link.username)}
                  >
                    <Icon />
                  </a>
                );
              })}
            </div>
          )}
          {links.length > 0 && (
            <div className={styles.links}>
              {links.map((link) => (
                <a
                  key={link.id}
                  href={link.url ? normalizeUrl(link.url) : link.url}
                  className={styles.link}
                >
                  {link.title}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
      <a
        style={{ color: "inherit", textDecoration: "none" }}
        href="https://onchain.bio"
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.badge}>
          <div>
            <div className={styles.powered}>powered by</div>
            <Logo className={styles.logo} />
          </div>
        </div>
      </a>
    </>
  );
}

export default Profile;
