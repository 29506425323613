import { IconButton, Paper } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import styles from "./ReorderableContainer.module.scss";

type Render = (data: any, index: number) => React.ReactNode;
type GetId = (data: any) => string;
type Delete = (index: number) => void;

type SortableItem = {
  render: Render;
  data: any;
  itemIndex: number;
  onDelete: Delete;
};

const SortableHandleWrapper = SortableHandle(() => (
  <DragIndicatorIcon className={styles.drag} />
));

const SortableWrapper = SortableElement(
  ({ render, data, itemIndex, onDelete }: SortableItem) => (
    <Paper className={styles.sortableItem}>
      <SortableHandleWrapper />
      <div className={styles.inner}>{render(data, itemIndex)}</div>
      <IconButton onClick={() => onDelete(itemIndex)}>
        <DeleteOutlineIcon color="error" />
      </IconButton>
    </Paper>
  )
);

const SortableList = SortableContainer(
  ({
    items,
    renderItem,
    getId,
    onDelete,
  }: {
    items: SortableItem[];
    renderItem: Render;
    getId: GetId;
    onDelete: Delete;
  }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableWrapper
            key={`item-${getId(value)}`}
            index={index}
            itemIndex={index}
            data={value}
            render={renderItem}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  }
);

type Props = {
  value: SortableItem[];
  onChange: (items: SortableItem[]) => void;
  renderItem: Render;
  getId: GetId;
};

const ReorderableContainer = ({
  onChange,
  value,
  renderItem,
  getId,
}: Props) => {
  return (
    <SortableList
      useDragHandle
      renderItem={renderItem}
      items={value}
      onSortEnd={({ oldIndex, newIndex }) => {
        onChange(arrayMove(value, oldIndex, newIndex));
      }}
      getId={getId}
      onDelete={(deleteIndex) => {
        onChange(value.filter((_v, index) => index !== deleteIndex));
      }}
    />
  );
};

export default ReorderableContainer;
