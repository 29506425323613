import {
  Alert,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
} from "@mui/material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { TextField as TextFieldRaw } from "@mui/material";

import { useGlobalStateContext } from "hooks/useGlobalState";
import usePromise from "react-use-promise";
import {
  getAdminDataForSlug,
  getCurrentAddress,
  slugToId,
  tokenData,
  updateToken,
} from "utils/chain";
import Device from "../Device";
import Profile from "../Profile";
import styles from "./Admin.module.scss";
import { Box } from "@mui/system";
import ImageUploadField from "./components/ImageUploadField";
import SocialLinksField, {
  validateSocialLinks,
} from "./components/SocialLinksField";
import LinksField, { validateLinks } from "./components/LinksField";
import ColorField from "./components/ColorField";
import { Logo } from "components/Logo";
import { useState } from "react";
import { Link } from "react-router-dom";
import { root } from "routes";
import { useEnv } from "utils/env";

const Spacer = () => <div className={styles.spacer} />;
const Section = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper elevation={2} className={styles.section}>
      {children}
    </Paper>
  );
};

const MintAlert = ({ onClose }: { onClose: () => void }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>Your profile is being minted or updated.</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Minting or updating your profile can take a few minutes. You can monitor
        the transaction in your Polygon wallet.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Okay</Button>
    </DialogActions>
  </Dialog>
);

function Admin({ loggedInSlug }: { loggedInSlug: string }) {
  const [data, error] = usePromise(
    () => getAdminDataForSlug(loggedInSlug!),
    []
  );

  const [didCreate, setDidCreate] = useState(false);
  const [isShowingMintAlert, setIsShowingMintAlert] = useState(false);

  if (error) {
    return <>{error.message || "error"}</>;
  }

  if (!data) {
    return null;
  }

  const { doesExist, currentAddress, profile } = data;

  const isExisting = doesExist || didCreate;

  return (
    <div>
      <div className={styles.nav}>
        {isShowingMintAlert && (
          <MintAlert
            onClose={() => {
              setIsShowingMintAlert(false);
            }}
          />
        )}
        <Link to={root} className={styles.logo}>
          <Logo showBeta />
        </Link>

        <Chip
          label={<span className={styles.chip}>{currentAddress}</span>}
          component="a"
          target="_blank"
          rel="noreferrer"
          href={`https://polygonscan.com/address/${currentAddress}`}
          clickable
          icon={<AccountBalanceWalletOutlinedIcon fontSize="small" />}
        />
      </div>
      <div className={styles.container}>
        <Formik
          initialValues={profile}
          validate={(values) => {
            const errors: { [key: string]: string } = {};

            if (!values.name) {
              errors.name = "Please add a Name";
            }

            if (!values.image) {
              errors.image = "Please add a Profile Picture";
            }

            if (!validateSocialLinks(values.socialLinks)) {
              errors.socialLinks = "All social icons must include a username";
            }

            if (!validateLinks(values.links)) {
              errors.links = "All links must include a title and valid url";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await updateToken(loggedInSlug!, values);
            setDidCreate(true);
            setIsShowingMintAlert(true);
          }}
        >
          {({ submitForm, isSubmitting, values, submitCount, errors }) => (
            <Form>
              <div className={styles.left}>
                <Box className={styles.leftInner}>
                  {isExisting ? (
                    <Alert
                      className={styles.alert}
                      severity="info"
                      sx={{ mb: "20px" }}
                    >
                      Your profile has been minted! You can visit your profile
                      at{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://onchain.bio/${loggedInSlug}`}
                      >
                        onchain.bio/{loggedInSlug}
                      </a>
                      . You can also view your profile token on the{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://polygonscan.com/token/${
                          useEnv.contractAddress
                        }?a=${slugToId(loggedInSlug)}`}
                      >
                        Polygon chain
                      </a>{" "}
                      and on{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://opensea.io/assets/matic/${
                          useEnv.contractAddress
                        }/${slugToId(loggedInSlug)}`}
                      >
                        OpenSea
                      </a>
                      . Please note, these links might take a few minutes to
                      activate after first minting your profile.
                    </Alert>
                  ) : (
                    <Alert
                      className={styles.alert}
                      severity="warning"
                      sx={{ mb: "20px" }}
                    >
                      Your profile has not yet been minted. Once your profile is
                      ready, press the "Mint" button at the bottom of the panel.
                      After minting, your profile will be public at{" "}
                      <a href="">onchain.bio/{loggedInSlug}</a>.
                    </Alert>
                  )}

                  <Section>
                    <TextFieldRaw
                      fullWidth
                      value={loggedInSlug}
                      disabled
                      label="Username"
                    />
                    <Spacer />
                    <TextFieldRaw
                      fullWidth
                      value={slugToId(loggedInSlug)}
                      disabled
                      label="tokenId"
                    />
                    <Spacer />
                    <Field
                      fullWidth
                      component={TextField}
                      name="name"
                      label="Display Name"
                    />
                    <Spacer />
                    <Field
                      fullWidth
                      component={TextField}
                      name="description"
                      label="Bio"
                    />
                    <Spacer />
                    <Field
                      component={ImageUploadField}
                      name="image"
                      label="Profile Image"
                    />
                    <Spacer />
                    <Field
                      component={ColorField}
                      name="color1"
                      label="Color 1"
                    />
                    <Spacer />
                    <Field
                      component={ColorField}
                      name="color2"
                      label="Color 2"
                    />
                  </Section>
                  <Spacer />
                  <Section>
                    <Field
                      component={SocialLinksField}
                      name="socialLinks"
                      label="Social Links"
                    />
                  </Section>
                  <Spacer />
                  <Section>
                    <Field component={LinksField} name="links" label="Links" />
                  </Section>
                  <Spacer />
                  {isSubmitting && <LinearProgress />}
                  {submitCount > 0 && Object.values(errors).length > 0 && (
                    <div style={{ color: "#d32f2f", marginTop: 10 }}>
                      Please fix the errors in the form
                    </div>
                  )}
                  <Button
                    sx={{ width: "100%", mt: 2 }}
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    startIcon={<SaveIcon />}
                  >
                    {doesExist ? "Update Profile" : "Mint Profile"}
                  </Button>
                </Box>
              </div>
              <div className={styles.right}>
                <Device className={styles.device}>
                  <Profile profile={values} />
                </Device>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const WrappedAdmin = () => {
  const { loggedInSlug } = useGlobalStateContext();

  if (!loggedInSlug) {
    return null;
  }

  return <Admin loggedInSlug={loggedInSlug} />;
};

export default WrappedAdmin;
