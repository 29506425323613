import React from "react";

import { SocialLinkTypes } from "./chain";

import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter.svg";
import { ReactComponent as TikTok } from "assets/icons/social/tiktok.svg";
import { ReactComponent as Twitch } from "assets/icons/social/twitch.svg";
import { ReactComponent as Reddit } from "assets/icons/social/reddit.svg";
import { ReactComponent as Github } from "assets/icons/social/github.svg";
import { ReactComponent as OpenSea } from "assets/icons/social/opensea.svg";

type ConfigType = {
  label: string;
  Icon: React.FunctionComponent;
  getUrl: (username: string) => string;
};

// On solo.to
// [...document.querySelectorAll('.soc-wrap')].map(el => el.querySelector('input').getAttribute('placeholder'))

export const SocialConfig: { [key: string]: ConfigType } = {
  [SocialLinkTypes.instagram]: {
    label: "Instagram",
    Icon: Instagram,
    getUrl: (username) => `https://www.instagram.com/${username}`,
  },
  [SocialLinkTypes.twitter]: {
    label: "Twitter",
    Icon: Twitter,
    getUrl: (username) => `https://twitter.com/${username}`,
  },
  [SocialLinkTypes.tiktok]: {
    label: "TikTok",
    Icon: TikTok,
    getUrl: (username) => `https://tiktok.com/@${username}`,
  },
  [SocialLinkTypes.twitch]: {
    label: "Twitch",
    Icon: Twitch,
    getUrl: (username) => `https://twitch.tv/${username}`,
  },
  [SocialLinkTypes.reddit]: {
    label: "Reddit",
    Icon: Reddit,
    getUrl: (username) => `https://reddit.com/user/${username}`,
  },
  [SocialLinkTypes.github]: {
    label: "Github",
    Icon: Github,
    getUrl: (username) => `https://github.com/${username}`,
  },
  [SocialLinkTypes.opensea]: {
    label: "OpenSea", 
    Icon: OpenSea,
    getUrl: (username) => `https://opensea.io/${username}`,
  },
};








