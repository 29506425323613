import React from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";

import usePromise from "react-use-promise";
import { getDataForSlug } from "utils/chain";
import Profile from "./Profile";
import styles from "./Page.module.scss";
import classNames from "classnames";

export const Loader = ({
  children,
  isOverlay,
}: {
  children: React.ReactNode;
  isOverlay?: boolean;
}) => {
  const node = (
    <div
      className={classNames(styles.loader, {
        [styles.isOverlay]: isOverlay,
      })}
    >
      {children}
    </div>
  );
  return createPortal(node, window.document.body);
};

function Page({ id }: { id: string }) {
  const [result, error] = usePromise(() => getDataForSlug(id), []);

  if (error) return <Loader>Error!</Loader>;

  if (!result)
    return (
      <Loader>
        <CircularProgress color="inherit" />
      </Loader>
    );

  return (
    <>
      <Profile profile={result} isReal />
    </>
  );
}

const WrappedPage = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Page id={id} />;
};

export default WrappedPage;
