export const urlToFile = async (url: string) => {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  return new File([data], "test.jpg", metadata);
};

export const fileToUrl = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.readAsDataURL(file);
  });
};

export const getImageUrl = async (image?: string | File) => {
  if (!image) {
    return undefined;
  }
  if (typeof image === "string") {
    return image;
  }
  const url = await fileToUrl(image);
  return url as string;
};