import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as routes from "./routes";
import { useWindowSize } from "rooks";

import Root from "./components/Root";
import Admin from "./components/Admin";
import Page, { Loader } from "./components/Page";

import { GlobalStateProvider } from "hooks/useGlobalState";
import "./App.module.scss";
import isValidBrowser from "utils/isValidBrowser";
import { Alert } from "@mui/material";

// // @ts-ignore
// window.__uploadCollectionMeta = async () => {
//   const client = getIpfsClient();
//   const file = await urlToFile(
//     "https://ipfs.io/ipfs/QmYou3Wf49nUi9121FD5ChuEZZtmoWw7G24rCc5s4y3Dne"
//   );
//   const imageHash = await uploadIpfsFile(file);
//   const image = ipfsUrl(imageHash);
//   const added = await client.add(
//     JSON.stringify({
//       name: "OpenSea Creatures",
//       description:
//         "OpenSea Creatures are adorable aquatic beings primarily for demonstrating what can be done using the OpenSea platform. Adopt one today to try out all the OpenSea buying, selling, and bidding feature set.",
//       image: image,
//       external_link: "https://openseacreatures.io",
//     })
//   );
//   await client.pin.add(added.path);
//   console.log(added.path)
//   return added.path;
// };

function App() {
  return (
    <GlobalStateProvider>
      <BrowserRouter>
        <Routes>
          <Route path={routes.admin} element={<Admin />} />
          <Route path={routes.page} element={<Page />} />
          <Route path={routes.root} element={<Root />} />
        </Routes>
      </BrowserRouter>
    </GlobalStateProvider>
  );
}

export default App;
