const MIN = 3;
export const MAX = 38;

enum FAIL_REASONS {
  MIN = "min",
  MAX = "max",
  LETTERS = "letters",
}

const errorMessages = {
  [FAIL_REASONS.LETTERS]: "Can only container letters (a-z)",
  [FAIL_REASONS.MIN]: `Must be at least ${MIN} characters`,
  [FAIL_REASONS.MAX]: `Must be no more than ${MAX} characters`,
};

export const letterOnlyPattern = /[^a-z]/;

export const validateSlug = (slug: string) => {
  const failures = [];

  if (slug.length < MIN) {
    failures.push(FAIL_REASONS.MIN);
  }

  if (slug.length > MAX) {
    failures.push(FAIL_REASONS.MAX);
  }

  if (slug.match(letterOnlyPattern)) {
    failures.push(FAIL_REASONS.LETTERS);
  }

  return failures.map((reason) => errorMessages[reason]).join(", ");
};
