import React from "react";
import styles from "./Section.module.scss";

export default function Section({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.title}>{title}</div>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
}
