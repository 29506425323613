import { Button, TextField } from "@mui/material";
import { FieldProps } from "formik";
import { SocialLinkTypes, tokenLink } from "utils/chain";
import { v4 as uuidv4 } from "uuid";
import ReorderableContainer from "./ReorderableContainer";
import AddIcon from "@mui/icons-material/Add";

export const validateLinks = (links: tokenLink[]) => {
  return !links.some(
    (link) => link.title.trim() === "" || link.url.trim() === ""
  );
};

const LinksField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue },
  ...props
}: FieldProps) => {
  const updateFieldByIndex = (
    changeIndex: number,
    newItem: Partial<tokenLink>
  ) => {
    setFieldValue(
      name,
      (value as tokenLink[]).map((item, index) => {
        if (index === changeIndex) {
          return { ...item, ...newItem };
        }
        return item;
      })
    );
  };
  return (
    <div>
      <ReorderableContainer
        value={value}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
        }}
        renderItem={(item: tokenLink, index) => (
          <>
            <TextField
              placeholder="Title"
              sx={{ mr: 1 }}
              fullWidth
              value={item.title}
              onChange={(e) =>
                updateFieldByIndex(index, { title: e.target.value })
              }
            />
            <TextField
              placeholder="URL"
              fullWidth
              type="url"
              value={item.url}
              onChange={(e) =>
                updateFieldByIndex(index, { url: e.target.value })
              }
            />
          </>
        )}
        getId={(item: tokenLink) => item.id}
      />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() =>
          setFieldValue(name, [
            ...value,
            { id: uuidv4(), url: "", title: "" } as tokenLink,
          ])
        }
      >
        Add
      </Button>
      {touched[name] && errors[name] && (
        <div style={{ color: "#d32f2f", marginTop: 10 }}>{errors[name]}</div>
      )}
    </div>
  );
};

export default LinksField;
