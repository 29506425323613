export {};

enum env {
  test = "test",
  prod = "prod",
}

const FORCE_LIVE = true;
const currentEnv =
  window.location.hostname === "localhost" && !FORCE_LIVE ? env.test : env.prod;

const testEnv = {
  infuraIpfsProjectId: "21qcczCu5JRATsXhgkXD2yLYdgO",
  infuraIpfsProjectSecret: "74f3d55d2e6fc6647fbdba817e2ec2ca",
  infuraEthAddress:
    "https://polygon-mumbai.infura.io/v3/0dbb204f40fb4a74b8ba19ed81b2d4bc",
  contractAddress: "0x59809a2C73980CaEf2B6596C8f46c6e2bE228616",
  ledgerContract:
    "https://mumbai.polygonscan.com/address/0x59809a2C73980CaEf2B6596C8f46c6e2bE228616",
  openSeaCollection: "https://testnets.opensea.io/collection/onchainbio-v2",
};

const prodEnv = {
  infuraIpfsProjectId: "21qcczCu5JRATsXhgkXD2yLYdgO",
  infuraIpfsProjectSecret: "74f3d55d2e6fc6647fbdba817e2ec2ca",
  infuraEthAddress:
    "https://polygon-mainnet.infura.io/v3/3623e62593f44d779db9137eba32ee59",
  contractAddress: "0x74EFF0857F999CeEd0FF06C94d773ACEf1af95aD",
  ledgerContract:
    "https://polygonscan.com/address/0x74EFF0857F999CeEd0FF06C94d773ACEf1af95aD",
  openSeaCollection: "https://opensea.io/collection/onchainbio",
};

export const useEnv = { [env.test]: testEnv, [env.prod]: prodEnv }[currentEnv];
