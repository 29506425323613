import styles from "./Logo.module.scss";
import cx from "classnames";
import LinkIcon from "@mui/icons-material/Link";
import { Chip } from "@mui/material";

export function Logo({
  className,
  showBeta,
}: {
  className?: string;
  showBeta?: boolean;
}) {
  return (
    <div className={cx(styles.container, className)}>
      {/* <LinkIcon
        style={{
          fontSize: "1.2em",
          transform: "rotate(90deg)",
          top: "0.17em",
          position: "relative",
        }}
      /> */}
      onchain.bio
      {showBeta && (
        <Chip
          sx={{ ml: "0.5em", fontSize: "0.5em", height: "2.9em" }}
          label="BETA"
        />
      )}
    </div>
  );
}
