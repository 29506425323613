import { FieldProps } from "formik";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import UploadIcon from "@mui/icons-material/Upload";
import { Avatar, Paper, Typography } from "@mui/material";
import styles from "./ImageUploadField.module.scss";
import usePromise from "react-use-promise";
import { getImageUrl } from "utils/file";

const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const ImageUploadField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newFile = await resizeFile(acceptedFiles[0]);
      setFieldValue(name, newFile);
    },
    [name, setFieldValue]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [valueUrl] = usePromise(() => getImageUrl(value), [value]);

  return (
    <Paper variant="outlined" {...getRootProps()} className={styles.container}>
      <input {...getInputProps()} />

      {valueUrl ? (
        <>
          <Avatar src={valueUrl} sx={{ width: 80, height: 80 }} />
          <Typography>Profile Picture</Typography>
        </>
      ) : (
        <>
          <UploadIcon fontSize="large" />
          <Typography>Upload a Profile Picture</Typography>
        </>
      )}

      {touched[name] && errors[name] && (
        <div style={{ color: "#d32f2f", marginTop: 10 }}>{errors[name]}</div>
      )}
    </Paper>
  );
};

export default ImageUploadField;
