import React from "react";
import { useEnv } from "utils/env";
import Hero from "./components/Hero";
import Section from "./components/Section";
import styles from "./Root.module.scss";

export const Link = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
    {children}
  </a>
);

const Em = ({ children }: { children: React.ReactNode }) => (
  <span className={styles.em}>{children}</span>
);

export default function Root() {
  return (
    <>
      <Hero />
      <div className={styles.sections}>
        <Section title="How do I create a profile?">
          <li>
            If you dont already have it, install the{" "}
            <Link href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn">
              MetaMask
            </Link>{" "}
            chrome plugin and set up your account
          </li>
          <li>
            Connect your metamask wallet to the Polygon (mainnet) network.{" "}
            <Link href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/">
              Click here
            </Link>{" "}
            for instructions.
          </li>
          <li>
            Transfer some funds (MATIC) to your polygon account address. You
            wont need much, minting and updating your profile typically costs
            less than $0.05 USD. Here is a{" "}
            <Link href="https://medium.com/@nifty.pixels/getting-matic-on-the-polygon-network-with-crypto-com-48374d4d78d5">
              good guide
            </Link>{" "}
            on how to get MATIC.
          </li>
          <li>
            Pick a unique username and click <Em>Connect</Em> above.
          </li>
          <li>Configure your profile to your liking</li>
          <li>
            Click the <Em>Mint</Em> button at the bottom of the page. Your
            metamask wallet will open and you will be asked to sign the
            transaction (The Polygon network requires a tiny gas fee to
            process, typically less than $0.05 USD)
          </li>
          <li>
            Your profile is now live for the world to see at{" "}
            <Em>onchain.bio/username</Em>
          </li>
          <li>
            You can view details about your profiles NFT on the{" "}
            <Link href={useEnv.ledgerContract}>Polygon public ledger</Link>, and
            view it on platforms like{" "}
            <Link href={useEnv.openSeaCollection}>OpenSea</Link>!
          </li>
          <li>
            To update your profile, simply <Em>Connect</Em> with your username,
            make the changes, and click the "Update" button. After paying a tiny
            gas fee, your profiles NFT will be updated.
          </li>
        </Section>
        <Section title="How does it work?">
          <li>
            Your unique username is converted into a <Em>uint256 tokenId</Em>{" "}
            that will become your profile NFTs unique identifier. Eg{" "}
            <Em>charlie</Em> ➡ <Em>102070017110804</Em>. The <Em>tokenId</Em> is
            calculated from the username as such: <Em>1</Em> then <Em>a = 00, b = 01 ... z = 25</Em>.
          </li>
          <li>
            When your profile is ready, metadata representing your profile is
            uploaded to <Link href="https://ipfs.io/">IPFS</Link> using standard
            metadata conventions that platforms like{" "}
            <Link href="https://opensea.io/">OpenSea</Link> recognize.
          </li>
          <li>
            After signing the transaction with your{" "}
            <Link href="https://metamask.io/">MetaMask</Link> wallet, your
            profile is minted as an NFT via our{" "}
            <Link href={useEnv.ledgerContract}>OnChainBio</Link> smart contract
            on the Polygon network.
          </li>
          <li>
            Whenever you need to change your profile, your profile metadata is
            re-uploaded to IPFS and your profile NFT is updated to point to the
            new metadata.
          </li>
          <li>
            When someone visits your profile, we fetch the token via the smart
            contract and render it for the visitor.
          </li>
          <li>
            We dont store anything in a database (in fact we dont even have a
            backend) everything we need to render your profile is stored on the
            public ledger and in IPFS.
          </li>
          <li>
            You can view (and even sell!) your profile NFT on a platform like
            OpenSea. Only the owner of the profile NFT will be able to modify
            it!
          </li>
        </Section>
      </div>
    </>
  );
}
