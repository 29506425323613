import { Button, Paper, Popover, Typography } from "@mui/material";
import { FieldProps } from "formik";
import React, { useCallback } from "react";
import { ChromePicker } from "react-color";
import { useDropzone } from "react-dropzone";
import styles from "./ColorField.module.scss";

const ColorField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue },
  label,
  ...props
}: FieldProps & { label: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Paper
        className={styles.button}
        variant="outlined"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Typography>{label}</Typography>
        <div className={styles.color} style={{ backgroundColor: value }} />
      </Paper>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ChromePicker
          color={value}
          onChange={(newValue) => setFieldValue(name, newValue.hex)}
        />
      </Popover>
      {touched[name] && errors[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </>
  );
};

export default ColorField;
